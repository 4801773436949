import React, { useRef } from 'react'
import clsx from 'clsx'
import { gsap } from 'gsap'

import { Answer, Question as QuestionStore } from 'stores/Quiz'

import './index.css'

interface Props {
  question: QuestionStore
  onAnswer: (answer: Answer) => void
}

const Question = ({ question, onAnswer }: Props) => {
  let refContainer = useRef(null)

  const handleAnswer = (answer: Answer) => {
    if (refContainer) {
      gsap.fromTo(refContainer, { duration: 0.3, opacity: 0.3 }, { duration: 0.5, opacity: 1 })
    }
    onAnswer(answer)
  }

  return (
    <div className="quiz-game__container" ref={(div) => (refContainer = div as any)}>
      <h2>{question.title}</h2>
      {question.image && (
        <div className="quiz-game__image-container">
          <img src={question.image} />
        </div>
      )}
      <ul className="quiz-game__answers">
        {question.answers.map((answer) => (
          <li
            key={answer.id}
            onClick={() => handleAnswer(answer)}
            className={clsx('quiz-game__answer', { 'quiz-game__answer_image': question.answersWithImage })}
          >
            <div className="quiz-game__answer__image-container" style={{ backgroundImage: `url(${answer.image})` }} />

            {answer.title}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Question
