import { makeAutoObservable, action, computed, set } from 'mobx'
import axios from 'axios'

const API_ENDPOINT = '/wp-admin/admin-ajax.php?action=ari_stream_quiz'

export enum QUIZ_STATE {
  LOADING,
  IDLE,
  IN_PROGRESS,
  COMPLETED
}

export class Answer {
  id: number
  title: string
  image?: string
  personalities: { [id: number]: { score: number } }
  answered = false
  order: number

  constructor(data: Partial<Answer>) {
    makeAutoObservable(this)
    set(this, data)
  }
}

export class Question {
  id: number
  title: string
  image?: string
  multiple: boolean
  page: number
  answers: Answer[]

  constructor(data: Partial<Question>) {
    makeAutoObservable(this)
    set(this, data)
  }

  get answersWithImage() {
    return !!this.answers.find((answer) => !!answer.image)
  }
}

export class Personality {
  id: number
  title: string
  content?: string
  image?: string
  score = 0

  constructor(data: Partial<Personality>) {
    makeAutoObservable(this)
    set(this, data)
  }
}

export interface Quiz {
  personalities: Personality[]
  questions: Question[]
}

class QuizStore {
  id: number
  title: string
  state: QUIZ_STATE = QUIZ_STATE.LOADING
  sessionKey: string
  personalityLimit = 1
  personalityOnlyMainContent = true
  personalities: Personality[] = []
  questions: Question[] = []
  currentQuestionId = 0

  constructor(id: number) {
    makeAutoObservable(this, {
      currentQuestion: computed,
      setAnswer: action,
      fetchQuiz: action
    })
    this.id = id
    this.fetchQuiz()
  }

  get currentQuestion() {
    if (this.state === QUIZ_STATE.COMPLETED) return null
    return this.questions[this.currentQuestionId]
  }

  get progress() {
    if (this.state === QUIZ_STATE.COMPLETED) return 100
    return Math.floor((this.currentQuestionId * 100) / this.questions.length)
  }

  get resultPersonalities() {
    return [...this.personalities]
      .sort((a, b) => b.score - a.score)
      .slice(0, this.personalityLimit)
      .reverse()
  }

  get mainPersonality() {
    return [...this.personalities].sort((a, b) => b.score - a.score)[0]
  }

  get showIntermediateResults() {
    return this.personalityLimit && this.personalityLimit > 1
  }

  start = () => {
    this.state = QUIZ_STATE.IN_PROGRESS
  }

  setAnswer = (answer: Answer) => {
    answer.answered = true
    this.personalities.map((personality) => {
      personality.score += answer.personalities[personality.id].score
    })
    if (this.currentQuestionId + 1 < this.questions.length) {
      this.currentQuestionId += 1
    } else {
      this.state = QUIZ_STATE.COMPLETED
      this.sendResults()
    }
  }

  reset = () => {
    this.currentQuestionId = 0
    this.personalities.map((per) => (per.score = 0))
    this.questions.map((q) => q.answers.map((a) => (a.answered = false)))
    this.start()
  }

  sendResults = async () => {
    const bodyFormData = new FormData()
    bodyFormData.append('ctrl', 'quiz-session_complete')
    bodyFormData.append('quiz_id', this.id.toString())
    bodyFormData.append('session_key', this.sessionKey)
    bodyFormData.append(
      'state',
      JSON.stringify({
        answers: this.questions.reduce((acc, q) => {
          acc[q.id] = q.answers.filter((a) => a.answered).map((a) => a.id)
          return acc
        }, {})
      })
    )
    try {
      await axios.post(API_ENDPOINT, bodyFormData)
    } catch (e) {
      console.error(e)
    }
  }

  fetchQuiz = async () => {
    try {
      const {
        data: {
          result: {
            sessionKey,
            data: {
              pages,
              quiz_meta: { personalities, title, personalityLimit, personalityOnlyMainContent }
            }
          }
        }
      } = await axios.post<App.QuizQueryInput>(
        API_ENDPOINT,
        {},
        { params: { ctrl: 'quiz-session_get-data', quiz_id: this.id } }
      )

      this.sessionKey = sessionKey
      this.title = title
      this.personalityLimit = personalityLimit
      this.personalityOnlyMainContent = personalityOnlyMainContent
      this.personalities = Object.keys(personalities).map((id) => this.createPersonality(+id, personalities[id]))
      pages.map((page, i) =>
        Object.values(page.questions).map((question) => {
          this.questions.push(this.createQuestion(i, question))
        })
      )
      this.state = QUIZ_STATE.IDLE
    } catch (e) {
      console.error(e)
    }
  }

  createPersonality = (id: number, personality: App.PersonalityInput): Personality => {
    return new Personality({
      id,
      title: personality.title,
      content: personality.content,
      image: personality.image?.url
    })
  }

  createQuestion = (page: number, question: App.QuestionInput): Question => {
    return new Question({
      id: question.question_id,
      page,
      title: question.question_title,
      image: question.image?.url,
      multiple: question.multiple,
      answers: Object.values(question.answers).map((answer) => this.createAnswer(answer))
    })
  }

  createAnswer = (answer: App.AnswerInput): Answer => {
    return new Answer({
      id: answer.answer_id,
      title: answer.answer_title,
      image: answer.image?.url,
      personalities: answer.personalities,
      order: answer.order
    })
  }
}

export default QuizStore
