import React from 'react'

import QuizStore from 'stores/Quiz'
import Share from 'components/Share'
import Button from 'components/Button'

import './index.css'

interface Props {
  quiz: QuizStore
}

const Results = ({
  quiz: { title, resultPersonalities, mainPersonality, reset, personalityOnlyMainContent }
}: Props) => {
  return (
    <div className="quiz-game__results">
      <p className="quiz-game__result__title">{title}</p>
      {resultPersonalities.map((personality, i) => (
        <div key={personality.id} className="quiz-game__result">
          <div>
            {resultPersonalities.length === 1 ? personality.title : `${personality.title}: ${personality.score}`}
          </div>
          {(!personalityOnlyMainContent || i === resultPersonalities.length - 1) && (
            <>
              {personality.image && <img src={personality.image} />}
              <div dangerouslySetInnerHTML={{ __html: personality.content! }}></div>
            </>
          )}
        </div>
      ))}
      <Share title={title} image={mainPersonality?.image} />
      <Button onClick={reset}>Повторить</Button>
    </div>
  )
}

export default Results
