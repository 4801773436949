import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  VKShareButton,
  VKIcon
} from 'react-share'

import './index.css'

interface Props {
  title: string
  image?: string
}

const Share = ({ title, image }: Props) => {
  return (
    <div className="quiz-game__share">
      <p>Поделиться результатами через</p>
      <div className="quiz-game__share__links">
        <VKShareButton url={window.location.href} title={title} image={image}>
          <VKIcon size={32} />
        </VKShareButton>
        <FacebookShareButton url={window.location.href} title={title}>
          <FacebookIcon size={32} />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href} title={title}>
          <TwitterIcon size={32} />
        </TwitterShareButton>
        <TelegramShareButton url={window.location.href} title={title}>
          <TelegramIcon size={32} />
        </TelegramShareButton>
      </div>
    </div>
  )
}

export default Share
