import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import './index.css'

window.QuizApp = (id, element) => {
  ReactDOM.render(
    <React.StrictMode>
      <App id={id} />
    </React.StrictMode>,
    element
  )
}
