import React from 'react'
import { configure } from 'mobx'

import Layout from 'components/Layout'
import Quiz from 'components/Quiz'

configure({ enforceActions: 'never' })

interface Props {
  id: number
}

const App = ({ id }: Props) => {
  return (
    <Layout>
      <Quiz id={id} />
    </Layout>
  )
}

export default App
