import React from 'react'

import './index.css'

interface Props {
  children: React.ReactElement | string
  onClick: () => void
}

const Button = ({ children, onClick }: Props) => {
  return (
    <button className="quiz-game__button" onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
