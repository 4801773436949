import React from 'react'
import { observer, useLocalObservable } from 'mobx-react'

import QuizStore, { QUIZ_STATE } from 'stores/Quiz'
import ProgressBar from './components/ProgressBar'
import IntermediateResults from './components/IntermediateResults'
import Question from './components/Question'
import Results from './components/Results'
import Button from 'components/Button'

import './index.css'

interface Props {
  id: number
}

const Quiz = ({ id }: Props) => {
  const store = useLocalObservable(() => new QuizStore(id))
  const { state, currentQuestion, setAnswer, start, personalities, progress, showIntermediateResults } = store

  if (state === QUIZ_STATE.LOADING) return <div>Loading...</div>

  return (
    <div className="quiz-game">
      {state !== QUIZ_STATE.IDLE && <ProgressBar progress={progress} />}
      {state === QUIZ_STATE.IN_PROGRESS && showIntermediateResults && (
        <IntermediateResults personalities={personalities} />
      )}
      {state === QUIZ_STATE.IN_PROGRESS && <Question question={currentQuestion!} onAnswer={setAnswer} />}
      {state === QUIZ_STATE.COMPLETED && <Results quiz={store} />}
      {state === QUIZ_STATE.IDLE && <Button onClick={start}>Начать</Button>}
    </div>
  )
}

export default observer(Quiz)
