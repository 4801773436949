import React from 'react'

import { Personality } from 'stores/Quiz'

import './index.css'

interface Props {
  personalities: Array<Personality>
}

const IntermediateResults = ({ personalities }: Props) => {
  return (
    <ul className="quiz-game__personalities">
      {personalities.map((personality) => (
        <li key={personality.id} className="quiz-game__personalities__item">
          <span className="quiz-game__personalities__label">{personality.title}:</span> {personality.score}
        </li>
      ))}
    </ul>
  )
}

export default IntermediateResults
