import React from 'react'
import clsx from 'clsx'

import './index.css'

interface Props {
  progress: number
}

const ProgressBar = ({ progress }: Props) => {
  return (
    <div className="quiz-game__progressbar">
      <div className="quiz-game__progressbar__container" style={{ width: `${progress}%` }}>
        <span className={clsx({ 'quiz-game__progressbar__label_inactive': progress === 0 })}>{`${progress}%`}</span>
      </div>
    </div>
  )
}

export default ProgressBar
